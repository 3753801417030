import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMServer from "react-dom/server";

import { AccordionList } from "../Partials/AccordionList/AccordionList";
import { Agenda } from "../Partials/Agenda/Agenda";
import { ArticlesArchive } from "./articlesArchive/ArticlesArchive";
import { CatalogListBlock } from "../Partials/Catalog/List/CatalogListBlock";
import { ClosePageButton } from "./closePageButton/ClosePageButton";
import { ComparisonBlock } from "../Commerce/Product/ComparisonBlock/ComparisonBlock";
import { ContactSectionHero } from "../Pages/ContactSectionPage/ContactSectionHero/ContactSectionHero";
import { ContactTile } from "../Partials/ContactCard/Tile/ContactTile/ContactTile";
import ContentBar from "../Partials/ContentBar/ContentBar";
import { ContentCard } from "../Partials/ContentCard/ContentCard";
import { ContentListTilesBlock } from "../Partials/ContentList/Tiles/ContentListTilesBlock";
import { Counter } from "../Partials/Counter/Counter";
import { CourseList } from "../Pages/CoursePortalPage/CourseList";
import { CourseSection } from "../Pages/ProductPage/CourseSection";
import { DiscoveriesListing } from "../Pages/Discoveries/DiscoveriesLandingPage/Listing/DiscoveriesListing";
import { DiscoveryTabs } from "../Pages/Discoveries/DiscoveryPage/Tabs/DiscoveryTabs";
import { DownloadsSection } from "../Partials/Downloads/DownloadsSection/Discovery/DownloadsSection";
import { DropDownList } from "../Partials/DropDownList/DropDownList";
import { ErrorPage } from "../Pages/ErrorPage/ErrorPage";
import { EventCampaignPageHeaderButtons } from "../Pages/EventCampaignPage/Header/Buttons/EventCampaignPageHeaderButtons";
import { FactBoxTile } from "../Partials/FactBox/Variants/Tiles/FactBoxTile";
import { FilterableProductCatalogItemList } from "../Partials/FilterableProductCatalogItemList/FilterableProductCatalogItemList";
import { Footer } from "../Partials/Footer/Footer";
import { FrontCover } from "../Partials/FrontCover/FrontCover";
import { GroupedContentList } from "../Partials/ContentList/Grouped/GroupedContentList";
import { Header } from "../Partials/Header/Header";
import { HeroVideo } from "../Partials/Hero/HeroVideo";
import { HighlightsSection } from "../Common/Sections/HighlightsSection/HighlightsSection";
import { ImageHotSpots } from "../Partials/ImageHotSpots/ImageHotSpots";
import { ImageViewer } from "../Partials/ImageViewer/ImageViewer";
import { ImageViewerFullScreen } from "../Partials/ImageViewer/ImageViewerFullScreen/ImageViewerFullScreen";
import { ImageWithFactsViewer } from "../Partials/ImageWithFacts/ImageWithFactsViewer";
import { KeyFeaturesBlock } from "../Commerce/Product/KeyFeatures/KeyFeaturesBlock";
import { LightBox } from "../Partials/LightBox/LightBox";
import { LinkBlock } from "../Partials/LinkBlock/LinkBlock";
import { LinkBlockWithImage } from "../Partials/LinkBlock/LinkBlockWithImage";
import { Microsite } from "../Microsite/Microsite";
import { NextPageSectionButton } from "./nextPageSectionButton/NextPageSectionButton";
import { NoLongerSold } from "../Commerce/Product/ProductBanner/NoLongerSold.kog.discovery";
import { OfficeLocationsContainer } from "../Partials/OfficeLocations/OfficeLocationsContainer";
import { OfficeLocationsWithMapListing } from "../Partials/OfficeLocations/OfficeLocationsWithMapListing/OfficeLocationsWithMapListing";
import { ProductBrowser } from "../Partials/ProductBrowser/ProductBrowser";
import { ProductCampaignPageHeaderButtons } from "../Pages/ProductCampaignPage/Header/Buttons/ProductCampaignPageHeaderButtons";
import { ProductCampaignSubPageHeaderButtons } from "../Pages/ProductCampaignSubPage/Header/Buttons/ProductCampaignSubPageHeaderButtons";
import { ProductCatalogItemCard } from "../Partials/ProductCatalogItemCard/ProductCatalogItemCard";
import { ProductCatalogItemList } from "../Partials/FilterableProductCatalogItemList/ProductCatalogItemList";
import { ProductContains } from "../Commerce/Product/ProductContains/ProductContains";
import { ProductIntroductionSection } from "../Commerce/Product/ProductIntroductionSection/ProductIntroductionSection";
import { ProductNavbar } from "../Commerce/Product/ProductNavbar/ProductNavbar";
import { ProductPageMaritimeNavbar } from "../Pages/ProductPage/ProductPageMaritimeNavbar/ProductPageMaritimeNavbar";
import { ProductPageStatus } from "../Pages/ProductPage/ProductPageStatus";
import { ProductSupportListing } from "../Pages/ProductSupportPage/ProductSupportListing/ProductSupportListing";
import { ProductTile } from "../Commerce/Product/Tile/ProductTile";
import { ProductsAccordionList } from "../Partials/ProductsAccordionList/ProductsAccordionList";
import ProductsListing from "../Commerce/Common/ProductsListing/ProductsListing";
import { ProductsListingProducts } from "../Commerce/Common/ProductsListing/ProductsListingProducts/ProductsListingProducts";
import { QuestionAndAnswerList } from "../Partials/QuestionAndAnswerList/QuestionAndAnswerList";
import { ReportsContainer } from "../Partials/Reports/ReportsContainer";
import { ResourcesBlock } from "../Pages/ResourcesPage/ResourcesBlock";
import { ResourcesSectionList } from "../Partials/ResourcesSection/ResourcesSectionList";
import { RichVideo } from "../Partials/RichVideo/RichVideo";
import { ScrollToTopButton } from "./ScrollToTopButton/ScrollToTopButton";
import { SearchInput } from "../Partials/Search/SearchInput";
import { SearchResults } from "../Partials/Search/SearchResults";
import { Share } from "../Partials/Share/Share";
import { SlidesNavigation } from "./SlidesNavigation/SlidesNavigation";
import { SpecificationsAndDownloadsSection } from "../Commerce/Product/SpecificationsAndDownloadsSection/SpecificationsAndDownloadsSection";
import { StoriesImageViewer } from "../Stories/Blocks/StoriesImageViewer/StoriesImageViewer";
import { StoriesSearchResults } from "../Stories/Partials/SearchResults/StoriesSearchResults";
import { Teaser } from "../Partials/Teasers/Teaser";
import { TechnicalSpecifications } from "../Partials/TechnicalSpecifications/TechnicalSpecifications";
import { Tile } from "./Discovery/Tile/Tile";
import { VacancyBlock } from "../Partials/Vacancy/VacancyBlock";
import { VacancyList } from "../Pages/VacancyListPage/VacancyList";
import { VesselListing } from "../Pages/VesselSegmentsLandingPage/VesselListing/VesselListing";
import { VesselPageNavbar } from "../Pages/VesselTypePage/VesselPageNavbar/VesselPageNavbar";
import { VesselSegmentPage } from "../Pages/VesselSegmentPage/VesselSegmentPage";
import { VideoFigure } from "../Partials/VideoFigure/VideoFigure";

const Components: Record<string, React.ReactNode> = {
  AccordionList: AccordionList,
  Agenda: Agenda,
  ArticlesArchive: ArticlesArchive,
  CatalogListBlock: CatalogListBlock,
  ClosePageButton: ClosePageButton,
  ComparisonBlock: ComparisonBlock,
  ContactSectionHero: ContactSectionHero,
  ContactTile: ContactTile,
  ContentBar: ContentBar,
  ContentCard: ContentCard,
  ContentListTilesBlock: ContentListTilesBlock,
  Counter: Counter,
  CourseList: CourseList,
  CourseSection: CourseSection,
  DiscoveriesListing: DiscoveriesListing,
  DiscoveryTabs: DiscoveryTabs,
  DownloadsSection: DownloadsSection,
  DropDownList: DropDownList,
  ErrorPage: ErrorPage,
  EventCampaignPageHeaderButtons: EventCampaignPageHeaderButtons,
  FactBoxTile: FactBoxTile,
  FilterableProductCatalogItemList: FilterableProductCatalogItemList,
  Footer: Footer,
  FrontCover: FrontCover,
  GroupedContentList: GroupedContentList,
  Header: Header,
  HeroVideo: HeroVideo,
  HighlightsSection: HighlightsSection,
  ImageHotSpots: ImageHotSpots,
  ImageViewer: ImageViewer,
  ImageViewerFullScreen: ImageViewerFullScreen,
  ImageWithFactsViewer: ImageWithFactsViewer,
  KeyFeaturesBlock: KeyFeaturesBlock,
  LightBox: LightBox,
  LinkBlock: LinkBlock,
  LinkBlockWithImage: LinkBlockWithImage,
  Microsite: Microsite,
  NextPageSectionButton: NextPageSectionButton,
  NoLongerSold: NoLongerSold,
  OfficeLocationsContainer: OfficeLocationsContainer,
  OfficeLocationsWithMapListing: OfficeLocationsWithMapListing,
  ProductBrowser: ProductBrowser,
  ProductCampaignPageHeaderButtons: ProductCampaignPageHeaderButtons,
  ProductCampaignSubPageHeaderButtons: ProductCampaignSubPageHeaderButtons,
  ProductCatalogItemCard: ProductCatalogItemCard,
  ProductCatalogItemList: ProductCatalogItemList,
  ProductContains: ProductContains,
  ProductIntroductionSection: ProductIntroductionSection,
  ProductNavbar: ProductNavbar,
  ProductPageMaritimeNavbar: ProductPageMaritimeNavbar,
  ProductPageStatus: ProductPageStatus,
  ProductSupportListing: ProductSupportListing,
  ProductTile: ProductTile,
  ProductsAccordionList: ProductsAccordionList,
  ProductsListing: ProductsListing,
  ProductsListingProducts: ProductsListingProducts,
  QuestionAndAnswerList: QuestionAndAnswerList,
  ReportsContainer: ReportsContainer,
  ResourcesBlock: ResourcesBlock,
  ResourcesSectionList: ResourcesSectionList,
  RichVideo: RichVideo,
  ScrollToTopButton: ScrollToTopButton,
  SearchInput: SearchInput,
  SearchResults: SearchResults,
  Share: Share,
  SlidesNavigation: SlidesNavigation,
  SpecificationsAndDownloadsSection: SpecificationsAndDownloadsSection,
  StoriesImageViewer: StoriesImageViewer,
  StoriesSearchResults: StoriesSearchResults,
  Teaser: Teaser,
  TechnicalSpecifications: TechnicalSpecifications,
  Tile: Tile,
  VacancyBlock: VacancyBlock,
  VacancyList: VacancyList,
  VesselListing: VesselListing,
  VesselPageNavbar: VesselPageNavbar,
  VesselSegmentPage: VesselSegmentPage,
  VideoFigure: VideoFigure,
};

declare let global: NodeJS.Global & typeof globalThis & typeof Components;

// register components as global required by ReactJS.NET
for (const component in Components) {
  global[component] = Components[component];
}

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

export type ComponentsType = typeof Components;
